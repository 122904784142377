import axios from 'axios';
import { getLoginCookie } from './cookie';
import * as fromStorage from './storage';
import { getExpiresFromToken, isExpired } from './token';

const API_URL = process.env.GATSBY_API_URL;

export const access = () => {
  if (isLoggedIn()) {
    if (isExpired()) {
      return doRefresh();
    } else {
      return true;
    }
  } else {
    fromStorage.clearExp();
    fromStorage.clearUser();
    return false;
  }
};

export const isLoggedIn = () => {
  const user = fromStorage.getUser();

  return !!user;
};

export const doLogin = (email, password, rememberMe) => {
  const url = `${API_URL}/v2/tokens`;
  const res = axios.post(
    url,
    { email, password, rememberMe },
    { withCredentials: true }
  );

  res.then((response) => {
    fromStorage.setUser(response.data.user);
    const expires = getExpiresFromToken(response.data.accessToken);
    fromStorage.setExp(expires);
  });

  return res;
};

export const doLogout = () => {
  const url = `${API_URL}/v2/tokens/logout`;
  const res = axios.post(
    url,
    {},
    {
      withCredentials: true,
    }
  );

  res.then(() => {
    fromStorage.clearExp();
    fromStorage.clearUser();
  });

  res.catch((err) => {
    console.log(err);
  });

  return res;
};

export const doRefresh = () => {
  const refreshUrl = `${API_URL}/v2/tokens/refresh`;
  const data = { token: getLoginCookie() };

  const res = axios.post(refreshUrl, data, {
    withCredentials: true,
    skipAuthorization: true,
  });

  res.then(
    (response) => {
      const expires = getExpiresFromToken(response.data.accessToken);
      fromStorage.setExp(expires);
    },
    () => {
      doLogout();
    }
  );

  res.catch((err) => {
    doLogout();
    return false;
  });

  return res;
};
