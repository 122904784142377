import { getExp } from './storage';

export const isExpired = () => {
  var expires = getExpires();

  if (!expires) {
    return false;
  }

  var isNow = new Date();

  if (isNow.getTime() > expires.getTime()) {
    return true;
  }

  return false;
};

export const getExpiresFromToken = (token) => {
  if (!token) return false;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  var obj = JSON.parse(window.atob(base64));
  var expires = obj.exp;
  return expires;
};

export const getExpires = () => {
  var exp = getExp();
  if (exp) {
    return new Date(exp * 1000);
  }

  return false;
};
